@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'node_modules/ngx-bar-rating/themes/br-default-theme';
@import 'styles/jurta-colors';
@import 'styles/panel.scss';


* {
  font-family: 'GT EESTI';
}
:root {
  --accent-color: #26BCBC;
  --primary-font-family: Roboto;
  --primary-font-family-bold: Roboto;
  --secondary-color: #DFA100;
  --link-text-color: #04518d;
  --fullhd-res: 1400px;
  --sxga-res: 1280px;
  --hd-res: 1200px;
}

@font-face {
  font-family: Roboto;
  font-weight: 200;
  src: url(assets/fonts/roboto/Roboto-Thin.ttf);
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  src: url(assets/fonts/roboto/Roboto-Light.ttf);
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  src: url(assets/fonts/roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  src: url(assets/fonts/roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: Roboto;
  font-weight: 600;
  src: url(assets/fonts/roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 400;
  src: url(assets/fonts/bebas/BebasNeue-Regular.ttf);
}

@font-face {
  font-family: GT Eesti;
  font-weight: 600;
  src: url(assets/fonts/GTeestipro/gteestiprodisplay_bold.otf);
  font-display: swap;
}

@font-face {
  font-family: GT Eesti;
  font-weight: 500;
  src: url(assets/fonts/GTeestipro/gteestiprodisplay_medium.otf);
  font-display: swap;
}

@font-face {
  font-family: GT Eesti;
  font-weight: 400;
  src: url(assets/fonts/GTeestipro/gteestiprodisplay_regular.otf);
  font-display: swap;
}

@font-face {
  font-family: GT Eesti;
  font-weight: 300;
  src: url(assets/fonts/GTeestipro/gteestiprodisplay_light.otf);
  font-display: swap;
}

@font-face {
  font-family: GT Eesti;
  font-weight: 200;
  src: url(assets/fonts/GTeestipro/gteestiprodisplay_thin.otf);
}

html {
  height: 100%;
}

body {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-size: 13px;
  margin: 0;
  height: 100%;
}

.ns-spinner-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

::ng-deep .real-property-details {
  max-width: 100%;
  max-height: 100%;
}

.real-property-details {
  max-width: 100%;
  max-height: 100%;
}

button {
  cursor: pointer;
}

.font-cyr {
  font-family: var(--primary-font-family);
}

.orange-border{
  color: #F28F17 !important;
  border: 1px solid #F28F17;
}

.gray-border {
  border: 0.5px solid #CDCDCD;
  border-radius: 25px;
}

.full-height{
  height: 100%;
}

.p-center {
  justify-content: center;
  align-items: center;
}

.p-not-margin{
  margin: 0 !important;
}
.p-not-margin-left{
  margin-left: 0 !important;;
}
.p-not-margin-right{
  margin-right: 0 !important;;
}
.p-not-margin-top{
  margin-top: 0 !important;;
}
.p-not-margin-bottom{
  margin-bottom: 0 !important;;
}
.p-not-padding{
  padding: 0 !important;;
}
.p-not-padding-left{
  padding-left: 0 !important;;
}
.p-not-padding-top{
  padding-top: 0 !important;
}
.p-not-padding-right{
  padding-right: 0 !important;;
}
.p-not-padding-bottom{
  padding-bottom: 0 !important;;
}

.p-accordion-header .p-accordion-header-link {
  border-radius: 15px !important;
}

.p-avatar img {
  object-fit: cover;
}

.mr-8 {
  margin-right: 8px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-16 {
  margin-top: 16px;
}

.mr-24 {
  margin-right: 24px;
}

.mr-12 {
  margin-right: 12px;
}

.ml-8 {
  margin-left: 8px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-72 {
  margin-bottom: 72px;
}

.ml-24 {
  margin-left: 24px;
}

.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.jurta-input {
  border-radius: 12px;
  outline: none;
  background: transparent;
  padding: 6px 12px;
  border: 1px solid #6A6877;
  color: $whiteEdgar;
  height: 36px;
}

@media screen and (max-width: 1366px) {
  .static-width {
    width: var(--sxga-res) !important;
  }
}

@media screen and (max-width: 1280px) {
  .static-width {
    min-width: var(--hd-res) !important;
    width: var(--hd-res) !important;
  }
}

.phone-field {
  a {
    padding-left: 18px;
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
    color: #131E34;
    &:hover {
      text-decoration: underline;
    }
  }
}

//TODO fix modal-filter
.p-dialog-mask {
  z-index: 1001;
}



.label-title {
  font-size: 1.75rem;
  @media screen and (max-width: 640px) {
    font-size: 1.5rem;
  }
}

.switch-wrapper {
  width: max-content;
  display: flex;
  position: absolute;
  justify-content: center;
  z-index: 1001;
  bottom: 32px;
  left: 0;
  right: 0;
  margin: auto;
  &.fixed {
    position: fixed;
  }
}
.switch-button {
  background: $whiteEdgar;
  border-radius: 12px;
  width: 108px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  .label {
    color: $veryBlack;
    font-weight: 500;
    margin-left: 6px;
    white-space: nowrap;
  }
}

.wrapper-width {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

.general-padding {
  @media (max-width: 1180px) and (min-width: 640px) {
    padding: 0 24px;
  }
  @media (max-width: 640px) {
    padding: 0 20px;
  }
}

input {
  &[readonly] {
    opacity: 0.6;
  }
}

.page-title {
  padding: 0;
  margin: 0;
  color: $whiteEdgar;
  font-weight: 700;
  font-size: 1.75rem;
  text-transform: uppercase;
  @media screen and (max-width: 640px) {
    font-size: 1.5rem;
  }
}

.page-content-general {
  .header-image {
    background-size: cover;
    background-position: center;
    position: relative;
    height: 676px;
    padding: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1180px) {
      padding: 32px;
    }

    @media (max-width: 640px) {
      height: 411px;
      padding: 20px;
    }

    .ivitrina-sign {
      position: absolute;
      top: 0;
      right: 80px;
      background: linear-gradient(90deg, #572ba3 0%, #8d4bff 94.07%);
      padding: 20px 12px 12px;
      border-bottom-left-radius: 16.8px;
      border-bottom-right-radius: 16.8px;
      z-index: 10;

      .img {
        width: 50px;
      }
      @media (max-width: 1180px) {
        right: 24px;
      }
      @media (max-width: 640px) {
        padding: 15px 10px 10px;
        right: 20px;

        .img {
          width: 42px;
        }
      }
      .sign-ivitrina {
        height: 50px;
        width: 50px;
        @media (max-width: 640px) {
          height: 33px;
          width: 33px;
        }
      }
    }

    .title {
      background: linear-gradient(90deg, rgba(255, 151, 18, 1) 0%, rgba(255, 248, 90, 1) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 6rem;
      font-weight: 700;
      text-transform: uppercase;

      @media (max-width: 1180px) {
        font-size: 3.5rem;
      }

      @media (max-width: 640px) {
        font-size: 1.75rem;
      }
    }

    .subtitle {
      font-size: 1.25rem;
      font-weight: 400;
      color: $white;
      margin: 36px 0;

      @media (max-width: 640px) {
        font-size: .875rem;
        margin: 24px 0;
      }
    }

    .button {
      display: flex;
    }
  }
}